import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import HistoryTrackDivider from './HistoryTrackDivider';
import { AdsAccountNames } from '../utils/const';
import { Language } from '../utils/types';

declare global {
  interface Window {
    attm: {
      set: {
        push: (callback: (initObject: AdInitObject) => void) => void;
      };
      setPage: (pageConfig: PageConfig) => void;
      setPlacement: (placement: PlacementData) => void;
      loadAds: () => void;
    };
  }
}
interface AdInitObject {
  noSlotReload: boolean;
  configId: string;
  manageAdserver: boolean; // use relevant yield to deliver GAM paths
  collapseEmptyDivs: boolean;
  collapseBeforeAdFetch: false;
  noGpt: boolean; //set to true when gpt is present on page.
  allowedDivIds: string[]; // set to an array to only load certain <div>s, example - ["divId1", "divId2"]
}

interface PlacementData {
  adUnit: string;
  adContainer: string;
  targeting?: Record<string, unknown>;
  functions?: {
    callback?: (obj: CallbackParams) => void;
  };
}

interface PageConfig {
  targeting: {
    pagetype: string;
  };
}

interface CallbackParams {
  isEmpty: boolean;
}

interface AdProps {
  adCounter: number;
}

const Ad: React.FC<AdProps> = ({ adCounter }) => {
  const container = useRef<HTMLDivElement>(null);
  const adContainer = useRef<HTMLDivElement>(null);

  // This was determined by the ad team
  // They plan to schedule ads with a height of 250px
  const expectedAdHeight = 50;

  const adUnitName = `inside-pos${adCounter}/radio`;
  const adId = generateAdUnitId('de', adUnitName);

  useEffect(() => {
    // useEffect runs after render, so the containers will exist
    // and the ad code will run and find containers
    try {
      window.attm.set.push(() => {
        window.attm.setPlacement({
          adUnit: adId,
          adContainer: `inside-pos${adCounter}-radio`,
          functions: {
            callback: (cbData) => {
              // We always render a 250px height container for the ad
              // Then manually outside of React render flow adjust its height
              // This resizes the container to fit the advert
              const ad = adContainer.current;
              const component = container.current;

              if (ad && component) {
                if (cbData.isEmpty) {
                  // No advert loaded, remove the entire container
                  component.style.display = 'none';
                } else {
                  // Resize container to match the loaded advert size
                  // TODO: This is not working, new adTech does not return size
                  // if (cbData.size) {
                  //   const adHeight = cbData.size[1];
                  //   ad.style.minHeight = `${adHeight}px`;
                  // }
                }
              }
            },
          },
        });
      });

      window.attm.set.push(() => {
        window.attm.loadAds();
      });
    } catch (error) {
      console.warn(
        `[ATTM] unhandled error on "setPlacement" - (inside-pos${adCounter}/radio):`,
        error,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={container}>
      <Box p={2} pb={1} textAlign="center">
        <div
          ref={adContainer}
          id={`inside-pos${adCounter}-radio`}
          data-ad-unit-id={adId}
          style={{
            minHeight: `${expectedAdHeight}px`,
          }}
        />
        <Box
          sx={{
            color: '#FFF',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            opacity: 0.75,
            textAlign: 'center',
          }}
        >
          Advert
        </Box>
      </Box>
      <HistoryTrackDivider />
    </div>
  );
};

export default Ad;

const generateAdUnitId = (locale: string, adUnitName: string) => {
  const account = '23178153471';
  const domain = AdsAccountNames[locale as Language];

  return `/${account}/${domain}/${adUnitName}`;
};
