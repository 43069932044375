import { useState, useEffect } from 'react';
import { TeaserSize } from '../utils/types';

// Temporary fix until new teaser type for radio widget is deployed
const useTeaserSize = (): TeaserSize => {
  const [teaserSize, setTeaserSize] = useState(TeaserSize.SMALLER);

  useEffect(() => {
    const smallerQuery = window.matchMedia('(max-width: 649px)');

    const updateTeaserSize = () => {
      if (smallerQuery.matches) {
        setTeaserSize(TeaserSize.SMALLER);
      } else {
        setTeaserSize(TeaserSize.BIGGER);
      }
    };

    // Initial check
    updateTeaserSize();

    smallerQuery.addEventListener('change', updateTeaserSize);

    return () => {
      smallerQuery.removeEventListener('change', updateTeaserSize);
    };
  }, []);

  return teaserSize;
};

export default useTeaserSize;
