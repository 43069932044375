import { useMemo } from 'react';
import useSWR from 'swr';
import { useAppContext } from '../context/AppContext';
import { API, errorCheck, FetchHeadersObject } from '../utils/const';
import type { Response } from '../utils/types';

const trackFetcher = async (url: string) => {
  const response = await fetch(url, FetchHeadersObject);
  const data = await response.json();

  errorCheck(data);

  return data.response;
};

const useCurrentTrackData = () => {
  const { language } = useAppContext();

  const localizedCurrentTrackAPI = useMemo(() => {
    const api = new URL(API.currentTrack);
    api.searchParams.append('userPreferredLanguage', language);
    return api.toString();
  }, [language]);

  const {
    data,
    error: currentTrackError,
    isLoading: currentTrackIsLoading,
  } = useSWR<Response>(localizedCurrentTrackAPI, trackFetcher, {
    refreshInterval: 500,
    dedupingInterval: 500,
  });

  return {
    data: data,
    error: currentTrackError,
    isLoading: currentTrackIsLoading,
  };
};

export default useCurrentTrackData;
