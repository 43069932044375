import { MessageForSelectItemEvent, TypeOfButtonClicked } from './types';

export const postMessageToParent = (button?: TypeOfButtonClicked) => {
  const message: MessageForSelectItemEvent = {
    item_name: 'GOAT Radio',
    item_variant: 'widget',
    item_category: 'radio',
    button,
  };

  if (window.parent) {
    try {
      window.parent.postMessage(message, '*');
    } catch (error) {
      console.error('Error sending message to parent:', error);
    }
  }
};
