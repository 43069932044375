import { getFromLocalStorage, setInLocalStorage } from './localStorage';
import { getConfig } from './config';
import { getCookieJson } from './cookie';
import { Language, Platform } from './types';

declare global {
  interface Window {
    dataLayer?: {
      push: (event: GTMTrackingEvent) => void;
    };
    dataLayerLogger: {
      enable: () => string;
      disable: () => string;
    };
  }
}

interface User {
  user_id?: string;
  user_login_status: 'logged in' | 'anonymous';
  age?: number;
  gender?: string;
}

type PlatformName = '20 Minuten' | '20 minutes' | 'Le Matin';

interface CommonEvent {
  page_canonical_url: string;
  page_title: string;
  page_type: 'radio';
  platform_type: 'web';
  platform_name: PlatformName | undefined;
  event_type: 'radio';
}

interface UserSession {
  userId: string;
}

export interface RadioPlayEvent extends CommonEvent, User {
  event: 'radio_play';
  radio_channel: 'radio_live';
}

export interface RadioStopEvent extends CommonEvent, User {
  event: 'radio_stop';
  radio_channel: 'radio_live';
}

export interface RadioResumeEvent extends CommonEvent, User {
  event: 'radio_resume';
  radio_channel: 'radio_live';
}
export interface HistoryPlayEvent extends CommonEvent, User {
  event: 'radio_play';
  radio_channel: 'radio_playlist';
}

export interface DownloadAppEvent extends CommonEvent, User {
  event: 'outbound_link_click';
  bid?: string;
  uid?: string;
}

export interface LaunchPlayerEvent extends CommonEvent, User {
  event: 'radio_launch_player';
  radio_channel: 'teaser_widget';
}

export type GTMTrackingEvent =
  | RadioPlayEvent
  | RadioStopEvent
  | RadioResumeEvent
  | HistoryPlayEvent
  | DownloadAppEvent
  | LaunchPlayerEvent;

type DataLayerLoggerStatus = 'enabled' | 'disabled';

interface TrackProps {
  platform: Platform;
  language: Language;
}

window.dataLayerLogger = {
  enable: () => {
    setInLocalStorage<DataLayerLoggerStatus>('dataLayerLogger', 'enabled');
    return 'Data layer logger was enabled';
  },
  disable: () => {
    setInLocalStorage<DataLayerLoggerStatus>('dataLayerLogger', 'disabled');
    return 'Data layer logger was disabled';
  },
};

const pushGTM = <T extends GTMTrackingEvent>(event: T) => {
  window.dataLayer?.push(event);

  if (
    getFromLocalStorage<DataLayerLoggerStatus>('dataLayerLogger') === 'enabled'
  ) {
    console.table(event);
  }
};

const getUserData = ({ language }: { language: Language }): User => {
  const config = getConfig(language);
  const sessionData = getCookieJson<UserSession>(
    `${config.cookie.userSession}`,
  );

  if (sessionData?.userId) {
    return {
      user_login_status: 'logged in',
      user_id: sessionData.userId,
    };
  }

  return {
    user_login_status: 'anonymous',
    user_id: undefined,
    age: undefined,
    gender: undefined,
  };
};

const getPlatformName = (platform: Platform): PlatformName => {
  switch (platform) {
    case '20min-de':
      return '20 Minuten';
    case '20min-fr':
      // not a typo, spec is here: https://docs.google.com/spreadsheets/d/1cQfAGOytbyyhSNZFMtgCiVvkJFnFERsFKC_Rp5PlO9M/edit#gid=702040847
      return '20 minutes';
    case 'lematin':
      return 'Le Matin';
    default:
      const _exhaustiveCheck: never = platform;
      throw new Error(`Unknown platform: ${platform}`);
  }
};

const getCommonEventData = ({
  platform,
  language,
}: TrackProps): CommonEvent => {
  const config = getConfig(language);

  return {
    event_type: 'radio',
    page_canonical_url: config.meta.canonicalUrl,
    page_title: config.meta.title,
    page_type: 'radio',
    platform_type: 'web',
    platform_name: getPlatformName(platform),
  };
};

export const trackRadioPlay = ({ platform, language }: TrackProps) =>
  pushGTM<RadioPlayEvent>({
    ...getCommonEventData({ platform, language }),
    ...getUserData({ language }),
    event: 'radio_play',
    radio_channel: 'radio_live',
  });

export const trackRadioStop = ({ platform, language }: TrackProps) =>
  pushGTM<RadioStopEvent>({
    ...getCommonEventData({ platform, language }),
    ...getUserData({ language }),
    radio_channel: 'radio_live',
    event: 'radio_stop',
  });

export const trackRadioResume = ({ platform, language }: TrackProps) =>
  pushGTM<RadioResumeEvent>({
    ...getCommonEventData({ platform, language }),
    ...getUserData({ language }),
    radio_channel: 'radio_live',
    event: 'radio_resume',
  });

export const trackHistoryPlay = ({ platform, language }: TrackProps) =>
  pushGTM<HistoryPlayEvent>({
    ...getCommonEventData({ platform, language }),
    ...getUserData({ language }),
    radio_channel: 'radio_playlist',
    event: 'radio_play',
  });

export const trackDownloadApp = ({ platform, language }: TrackProps) =>
  pushGTM<DownloadAppEvent>({
    ...getCommonEventData({ platform, language }),
    ...getUserData({ language }),
    event: 'outbound_link_click',
    bid: String(window.tamediaBID),
    uid: String(window.Dakt2?.getUserId()),
  });

export const trackLaunchPlayer = ({ platform, language }: TrackProps) =>
  pushGTM<LaunchPlayerEvent>({
    ...getCommonEventData({ platform, language }),
    ...getUserData({ language }),
    event: 'radio_launch_player',
    radio_channel: 'teaser_widget',
  });
