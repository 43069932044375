import { Language } from './types';

export const FetchHeadersObject = {
  method: 'POST',
  headers: {
    'X-API-KEY': process.env.REACT_APP_API_KEY as string,
  },
};

const server = 'https://pub.dmd2.com/api/v1/stats/';

export const API = {
  currentTrack: `${server}?channel=1`,
  history: `${server}?channel=1&history=true`,
};

export const RadioChannels: Record<Language, string> = {
  de: '1',
  fr: '2',
};

export const LiveStreamsURLs: Record<Language, string> = {
  de: `https://20min.dmd2streaming.com/20minuten_radio_64.aac`,
  fr: `https://20min.dmd2streaming.com/20minuten_fr_radio_128.mp3`,
};

export const errorCheck = (data: any) => {
  if (data.status !== 200) {
    const error = new Error('An error occurred while fetching the data');
    throw error;
  }
};

export const globalStyles = {
  body: {
    backgroundColor: '#C929FF',
  },
  'html, body, #root': {
    height: '100%',
  },
};

export const backgroundImageStyles = {
  backgroundImage: `url('/img/goat-background.png')`,
  backgroundRepeat: 'repeat',
  backgroundSize: '72px 72px',
};

export const AdsAccountNames: Record<Language, string> = {
  de: 'de-20minuten.ch',
  fr: 'fr-20minutes.ch',
};

export const GoatRadioAppLinks = {
  ios: 'https://apps.apple.com/ch/app/goat-radio/id6738387219',
  android:
    'https://play.google.com/store/apps/details?id=ch.dmd.GoatRadio&pcampaignid=web_share',
};
