import { useMemo } from 'react';
import useSWR from 'swr';
import { useAppContext } from '../context/AppContext';
import { API, errorCheck, FetchHeadersObject } from '../utils/const';
import type { TrackHistory } from '../utils/types';

const historyFetcher = async (url: string) => {
  const response = await fetch(url, FetchHeadersObject);
  const data = await response.json();

  errorCheck(data);

  return data.response.track.history;
};

const useHistoryData = () => {
  const { language } = useAppContext();

  const localizedHistoryAPI = useMemo(() => {
    const api = new URL(API.history);
    api.searchParams.append('userPreferredLanguage', language);
    return api.toString();
  }, [language]);

  const {
    data: history,
    error: historyError,
    isLoading: historyIsLoading,
  } = useSWR<TrackHistory[]>(localizedHistoryAPI, historyFetcher, {
    refreshInterval: 20000,
  });

  return {
    data: history,
    error: historyError,
    isLoading: historyIsLoading,
  };
};

export default useHistoryData;
