import { styled } from '@mui/material/styles';
import { FC } from 'react';

const Kickword: FC = () => {
  const text = 'live';

  return (
    <KickwordWrapper>
      <KickwordText>{text}</KickwordText>
    </KickwordWrapper>
  );
};

export default Kickword;

const KickwordWrapper = styled('div')(() => ({
  backgroundColor: 'rgba(201, 41, 255, 0.10)',
  padding: '0px 8px',
  borderRadius: 4,
  maxWidth: 48,
  display: 'flex',
}));

const KickwordText = styled('p')(() => ({
  color: '#C929FF',
  textAlign: 'center',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  textTransform: 'uppercase',
  margin: 0,
}));
