import { styled } from '@mui/material/styles';
import { FC } from 'react';
import useCurrentTrackData from '../../../hooks/useCurrentTrackData';

const AlbumCover: FC = () => {
  const { data, error, isLoading } = useCurrentTrackData();

  // TODO: could benefit from prettier loading and error display
  if (error) return <div>Failed to load</div>;
  if (isLoading) return <div>Loading...</div>;

  const currentTrack = data?.track.playing;

  return (
    <AlbumCoverImage>
      <img src={currentTrack?.trackAlbumCover} alt={currentTrack?.trackTitle} />
    </AlbumCoverImage>
  );
};

export default AlbumCover;

const AlbumCoverImage = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 70,
  height: 70,
  objectFit: 'cover',
  overflow: 'hidden',
  flexShrink: 0,
  borderRadius: 4,

  '& > img': {
    width: '100%',
    height: '100%',
  },

  [theme.breakpoints.up('gw')]: {
    width: 88,
    height: 88,
  },
}));
